<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator">
        <div class="table-operator-title">{{ name }}</div>
        <a-button type="primary" @click="handleEdit({ id: 0 })">新建</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="page" size="middle" @change="more">
          <template slot="status" slot-scope="text">
            <span>
              {{ text.status==1?'启用':'禁用' }}
            </span>
          </template>
          <span slot="action" slot-scope="text, record">
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" v-if="record.id > 1" />
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.id > 1">
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a>删除</a>
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 提交表单 -->
    <create-form ref="createModal" @ok="handleOk"/>
  </div>
</template>

<script>
import CreateForm from "./modules/CreateForm";
import { userlist,userupdate ,joblist} from "../../../api/index.js";

export default {
  components: {
    CreateForm,
  },
  methods: {
    async more(e) {
      let res = await userlist({ current: e.current, size: 20, btime: '00:00:00' });
      if (res.status == 200) {
        this.item = res.data.records;
        this.page.total = res.data.total;
        this.page.current = res.data.current_page;
        // this.$store.commit('set_auth',res.data.auth)
        this.item.forEach(v => {
          v.updateTime = this.timestampToTime(v.updateTime)
        })

      }
    },
    handleEdit(record) {
      this.id = record.id;
      let arr=[]
      this.jobList.map(v=>{
          let obj={
            value:'',
            label:''
          }
          arr.push(obj)
      })
      for(var i =0;i<arr.length;i++){
        arr[i].value=this.jobList[i].id
        arr[i].label=this.jobList[i].postName
      }
     this.job=arr
      this.$refs.createModal.init(record);
    },
    //将时间戳转换成日期格式
    timestampToTime(timestamp) {
      var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
      return Y + M + D + h + m + s;
    },
   async handleOk(e) {
      let res=await userupdate({
        id:this.id,
        phone	:e.phone,
        nickName:e.nickName,
        status:e.status,
        sex:e.sex,
        postIds:e.ids
      })
      if(res.status==200){
        this.$message.success('修改成功')
        this.$refs.createModal.cancel();
        this.more()
      }

    },
    del(e) {
    },
    async getJob(){
      let res=await joblist()
      this.jobList=res.data.records
    },
  },
  mounted() {
    this.more({ current: 1 });
    this.getJob();
  },
  data() {
    return {
      name: "",
      id: 0,
      queryParam: {},
      page: { pageSize: "10", total: 0, current: 0 },
      columns: [
        { key: "id", title: "编号", dataIndex: "id", width: 80 },
        { title: "登录账号", dataIndex: "account" },
        { title: "员工姓名", dataIndex: "nickName" },
        { title: "启用状态", width: 120,  key: "status", scopedSlots: { customRender: "status" }  },
        { title: "更新时间", dataIndex: "updateTime" },
        { title: "操作", key: "action", width: 100, scopedSlots: { customRender: "action" } },
      ],
      item: [],
      jobList:[],
      job:[]
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>